import { default as _91id_93hsGq36HwbYMeta } from "/tmp/8dd04e7a5867dfc/pages/applicant/[id].vue?macro=true";
import { default as _91id_93kBO8c21MTqMeta } from "/tmp/8dd04e7a5867dfc/pages/apply/[id].vue?macro=true";
import { default as _91id_931cajsWaLCBMeta } from "/tmp/8dd04e7a5867dfc/pages/apply/perfil/[id].vue?macro=true";
import { default as thanksANBsy9RCgeMeta } from "/tmp/8dd04e7a5867dfc/pages/apply/thanks.vue?macro=true";
import { default as _91_46_46_46slug_93IZMZt112WIMeta } from "/tmp/8dd04e7a5867dfc/pages/auth/password/[...slug].vue?macro=true";
import { default as forgotoBqaUpSmAEMeta } from "/tmp/8dd04e7a5867dfc/pages/auth/password/forgot.vue?macro=true";
import { default as _91_46_46_46slug_93XTFzfRcjkJMeta } from "/tmp/8dd04e7a5867dfc/pages/auth/signin/[...slug].vue?macro=true";
import { default as indexMfN2Jqlv2gMeta } from "/tmp/8dd04e7a5867dfc/pages/auth/signin/index.vue?macro=true";
import { default as _91email_93uqMHfxqEtmMeta } from "/tmp/8dd04e7a5867dfc/pages/auth/signup/[email].vue?macro=true";
import { default as indexqAHyls91ntMeta } from "/tmp/8dd04e7a5867dfc/pages/auth/signup/index.vue?macro=true";
import { default as _91_46_46_46slug_937ZG6BMWbL6Meta } from "/tmp/8dd04e7a5867dfc/pages/auth/verifyemail/[...slug].vue?macro=true";
import { default as jobs7MMV9fujjKMeta } from "/tmp/8dd04e7a5867dfc/pages/company/[id]/jobs.vue?macro=true";
import { default as indexDQShfSuCBcMeta } from "/tmp/8dd04e7a5867dfc/pages/company/index.vue?macro=true";
import { default as onboarding0WT0u9cjz7Meta } from "/tmp/8dd04e7a5867dfc/pages/company/onboarding.vue?macro=true";
import { default as indexAGTD1oKgGnMeta } from "/tmp/8dd04e7a5867dfc/pages/index.vue?macro=true";
import { default as _91applicant_93nXYzyGb7yoMeta } from "/tmp/8dd04e7a5867dfc/pages/job/[job]/applicants/[applicant].vue?macro=true";
import { default as index2k3mKRlpW6Meta } from "/tmp/8dd04e7a5867dfc/pages/job/[job]/applicants/index.vue?macro=true";
import { default as duplicateg7o30CFFatMeta } from "/tmp/8dd04e7a5867dfc/pages/job/[job]/duplicate.vue?macro=true";
import { default as indexdN2v8P3ePjMeta } from "/tmp/8dd04e7a5867dfc/pages/job/[job]/index.vue?macro=true";
import { default as archivedYuCgRcCSD2Meta } from "/tmp/8dd04e7a5867dfc/pages/job/archived.vue?macro=true";
import { default as closedZ1m9spKXJyMeta } from "/tmp/8dd04e7a5867dfc/pages/job/closed.vue?macro=true";
import { default as newYRcQm27DEuMeta } from "/tmp/8dd04e7a5867dfc/pages/job/new.vue?macro=true";
import { default as openedwpY8TMgGNPMeta } from "/tmp/8dd04e7a5867dfc/pages/job/opened.vue?macro=true";
import { default as pausedWx7IimrxmuMeta } from "/tmp/8dd04e7a5867dfc/pages/job/paused.vue?macro=true";
import { default as indexFMoEtRrNbpMeta } from "/tmp/8dd04e7a5867dfc/pages/plans/index.vue?macro=true";
import { default as settingst1QubXjxctMeta } from "/tmp/8dd04e7a5867dfc/pages/settings.vue?macro=true";
import { default as indexXXpMK8bDV5Meta } from "/tmp/8dd04e7a5867dfc/pages/thanks/index.vue?macro=true";
import { default as indexEntMS1QRGLMeta } from "/tmp/8dd04e7a5867dfc/pages/user/index.vue?macro=true";
export default [
  {
    name: "applicant-id",
    path: "/applicant/:id()",
    meta: _91id_93hsGq36HwbYMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/applicant/[id].vue")
  },
  {
    name: "apply-id",
    path: "/apply/:id()",
    component: () => import("/tmp/8dd04e7a5867dfc/pages/apply/[id].vue")
  },
  {
    name: "apply-perfil-id",
    path: "/apply/perfil/:id()",
    component: () => import("/tmp/8dd04e7a5867dfc/pages/apply/perfil/[id].vue")
  },
  {
    name: "apply-thanks",
    path: "/apply/thanks",
    component: () => import("/tmp/8dd04e7a5867dfc/pages/apply/thanks.vue")
  },
  {
    name: "auth-password-slug",
    path: "/auth/password/:slug(.*)*",
    component: () => import("/tmp/8dd04e7a5867dfc/pages/auth/password/[...slug].vue")
  },
  {
    name: "auth-password-forgot",
    path: "/auth/password/forgot",
    component: () => import("/tmp/8dd04e7a5867dfc/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-signin-slug",
    path: "/auth/signin/:slug(.*)*",
    meta: _91_46_46_46slug_93XTFzfRcjkJMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/auth/signin/[...slug].vue")
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    meta: indexMfN2Jqlv2gMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/auth/signin/index.vue")
  },
  {
    name: "auth-signup-email",
    path: "/auth/signup/:email()",
    component: () => import("/tmp/8dd04e7a5867dfc/pages/auth/signup/[email].vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: indexqAHyls91ntMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/auth/signup/index.vue")
  },
  {
    name: "auth-verifyemail-slug",
    path: "/auth/verifyemail/:slug(.*)*",
    component: () => import("/tmp/8dd04e7a5867dfc/pages/auth/verifyemail/[...slug].vue")
  },
  {
    name: "company-id-jobs",
    path: "/company/:id()/jobs",
    component: () => import("/tmp/8dd04e7a5867dfc/pages/company/[id]/jobs.vue")
  },
  {
    name: "company",
    path: "/company",
    meta: indexDQShfSuCBcMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/company/index.vue")
  },
  {
    name: "company-onboarding",
    path: "/company/onboarding",
    meta: onboarding0WT0u9cjz7Meta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/company/onboarding.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexAGTD1oKgGnMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/index.vue")
  },
  {
    name: "job-job-applicants-applicant",
    path: "/job/:job()/applicants/:applicant()",
    meta: _91applicant_93nXYzyGb7yoMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/job/[job]/applicants/[applicant].vue")
  },
  {
    name: "job-job-applicants",
    path: "/job/:job()/applicants",
    meta: index2k3mKRlpW6Meta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/job/[job]/applicants/index.vue")
  },
  {
    name: "job-job-duplicate",
    path: "/job/:job()/duplicate",
    meta: duplicateg7o30CFFatMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/job/[job]/duplicate.vue")
  },
  {
    name: "job-job",
    path: "/job/:job()",
    meta: indexdN2v8P3ePjMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/job/[job]/index.vue")
  },
  {
    name: "job-archived",
    path: "/job/archived",
    meta: archivedYuCgRcCSD2Meta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/job/archived.vue")
  },
  {
    name: "job-closed",
    path: "/job/closed",
    meta: closedZ1m9spKXJyMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/job/closed.vue")
  },
  {
    name: "job-new",
    path: "/job/new",
    meta: newYRcQm27DEuMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/job/new.vue")
  },
  {
    name: "job-opened",
    path: "/job/opened",
    meta: openedwpY8TMgGNPMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/job/opened.vue")
  },
  {
    name: "job-paused",
    path: "/job/paused",
    meta: pausedWx7IimrxmuMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/job/paused.vue")
  },
  {
    name: "plans",
    path: "/plans",
    meta: indexFMoEtRrNbpMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/plans/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingst1QubXjxctMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/settings.vue")
  },
  {
    name: "thanks",
    path: "/thanks",
    meta: indexXXpMK8bDV5Meta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/thanks/index.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: indexEntMS1QRGLMeta || {},
    component: () => import("/tmp/8dd04e7a5867dfc/pages/user/index.vue")
  }
]