import validate from "/tmp/8dd04e7a5867dfc/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/tmp/8dd04e7a5867dfc/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/tmp/8dd04e7a5867dfc/middleware/auth.ts"),
  onboarding: () => import("/tmp/8dd04e7a5867dfc/middleware/onboarding.ts"),
  "recruiter-only": () => import("/tmp/8dd04e7a5867dfc/middleware/recruiterOnly.ts")
}